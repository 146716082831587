import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppCommonService } from "@services/app-common.service";
import { AuthService } from "@services/auth.service";
import { ModeDevice } from "../constants/application/application-constants";
import { AppDesktopFallbackRoutes } from "../constants/routes/app-desktop-fallback-routes-constants";

declare var WebViewAppConnect: any;
@Injectable({ providedIn: "root" })
export class RouterGuard  {
  noChatbotArray = [
    "/",
    "/product/",
    "/lp/",
    "/cart",
    "/checkout",
    "/wv/livestreaming",
    "/wv/rewards",
    "/login",
    "/wv/virtual-try-on",
    "/wv/beautyquiz",
    "/wv/categories",
    "/wv/price-reveal",
    "/brand",
    "/explore",
    '/wv/skin-analyser-error',
    '/wv/freebies',
    '/profile'
  ];
  desktopFallbackRoutes = [
    AppDesktopFallbackRoutes.beautyStudio,
    AppDesktopFallbackRoutes.beautyQuiz,
    AppDesktopFallbackRoutes.skinExpert
  ]
  mobilePageNotFoundRoutes = [
    AppDesktopFallbackRoutes.appDownload,
    AppDesktopFallbackRoutes.tryon,
    AppDesktopFallbackRoutes.survey
  ]
  isSSR: boolean = typeof window === "undefined";
  constructor(
    private authService: AuthService,
    private commonService: AppCommonService,
  ) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const cookieModeDevice =  this.authService.getModeDevice();
    const isMobileWebMode = cookieModeDevice == ModeDevice.mobile;
    const isDesktopWebMode = cookieModeDevice === ModeDevice.desktop;
    if (isDesktopWebMode && this.desktopFallbackRoutes.some(r => state?.url.includes(r))) {
      this.commonService.handleRoute(AppDesktopFallbackRoutes.appDownload);
      return false;
    } else if (isMobileWebMode && this.mobilePageNotFoundRoutes.some(r => state?.url.includes(r))) {
      
      this.commonService.handleRoute("/404");
      return false;
    } else if (isMobileWebMode && state.url.includes('yt-livestream')) {
      if (!this.isSSR) window.location.href = location.origin + '/mb/appdownloadpage';
      return false;
    }
    this.chatbotPages(state?.url, route?.data);
    if (route.data["name"] === undefined) {
      if (!this.isSSR && !this.authService.isInitCalled) {
        this.authService.getInitState();
      }
    }
    return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.chatbotPages(state.url);
    if (route.data["name"] === undefined) {
      if (!this.isSSR && !this.authService.isInitCalled) {
        this.authService.getInitState();
      }
    }
    return true;
  }
  async chatbotPages(url, routeData?: any) {
    console.log("stateUrl:", url);
    let enableChatbot = true;
    await this.noChatbotArray.map((obj) => {
      if (obj != "/" && url.indexOf(obj) > -1) {
        enableChatbot = false;
        return;
      }
    });
    if (url == "/" && this.noChatbotArray.includes("/")) {
      enableChatbot = false;
    }
    enableChatbot = "chatbot" in routeData ? routeData?.chatbot : enableChatbot;
    if (!this.isSSR && enableChatbot) {
      if (!this.authService.isUserLoggedIn()) {
      } else {
        if (!this.commonService.chatbotInitialized) {
          this.authService.loadChatBotScript();
        } else {
          window?.["fcWidget"]?.show();
        }
      }
    } else {
      if (!this.isSSR) {  
        window?.["fcWidget"]?.hide();
      }
    }
  }
}
